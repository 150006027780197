.hosting-vds {
    .subscription-plans-table__header.brilliant:before {
        background: #dcd5e4;
    }
    .subscription-plans-table__header.brilliant.first:after {
        left: -2px;
        right: auto;
        width: 2px;
        border-right: 2px solid #dcd5e4;
    }
    .subscription-plans-table__header.brilliant.first:before {
        right: 3px;
        width: 227px;
    }
    .subscription-plans-table__content.brilliant.first:after {
        left: -2px;
        right: auto;
        width: 2px;
        border-right: 2px solid #dcd5e4;
    }
    .subscription-plans-table__content.brilliant.first:before {

    }
    .subscription-plans-table__content.brilliant:before {
        width: 232px;
    }
    .subscription-plans-table__header.gold {
        position: relative;
        z-index: 200;
    }
    .subscription-plans-table__header.gold:after {
        content: "";
        position: absolute;
        top: -7px;
        left: -3px;
        width: 257px;
        height: calc(100% + 10px);
        border-left: 3px solid #d2a509;
        border-right: 3px solid #d2a509;
        z-index: 103;
    }
    .subscription-plans-table__header.gold:after {
        width: 230px;
        border-left: 3px solid #d2a509;
        border-right: 3px solid #d2a509;
    }
    .subscription-plans-table__content.gold:after {
        width: 230px;
        border-left: 3px solid #d2a509;
        border-right: 3px solid #d2a509;
        left: -3px;
    }
    .subscription-plans-table__header:first-child {
        width: 250px;
    }
    .subscription-plans-table__content.brilliant.first:before {
        width: 227px;
        right: 3px;
        background: #dcd5e4;
    }
    .subscription-plans-table__aside {
        width: 250px;
    }
    .subscription-plans-table__header {
        width: 230px;
    }
    .subscription-plans-table__content {
        width: 230px;
    }
    .subscription-plans-table__header.brilliant:before {
        width: 230px;
    }
    .subscription-plans-table__header.gold:before {
        width: 230px;
        content: none;
    }
    .subscription-plans-table__content.gold:before {
        width: 230px;
        content: none;
    }
    .subscription-plans-table__content.gold {
        z-index: 200;
    }
    .subscription-plans-table__content.gold:after {
        content: "";
        position: absolute;
        bottom: -7px;
        left: -3px;
        width: 230px;
        height: calc(100% + 10px);
        border-left: 3px solid #d2a509;
        border-right: 3px solid #d2a509;
        z-index: 103;
    }
}

.page-subnavigation {
    position: absolute;
    top: 197px;
    left: 0;
    width: 100%;
    display: none;
    background: #13d38d;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.19), inset 0 0 16px rgba(0, 0, 0, 0.11), inset 0 12px 16px -8px rgba(0, 0, 0, 0.15);
    &.show {
        display: block;
    }
    li {
        background: none !important;
        &:after {
            content: none;
        }
        &.active {
            a {
                background: none !important;
                box-shadow: inset 0 -3px 0 0 #fff;
            }
        }
        a {
            &:hover {
                background: none !important;
                box-shadow: inset 0 -3px 0 0 #fff;
            }
        }
    }
}

.ws {
    &-key-visual {
        background: url(images/ws.jpg) 0 0 no-repeat;
        background-size: cover;
        background-position: 50% 50%;
        text-align: center;
        font-family: "SegoeUI-Regular", Arial, "Helvetica CY", "Nimbus Sans L", sans-serif;
        padding-bottom: 102px;
        &__title {
            margin-top: 61px;
            background-color: #fff;
            border-radius: 3px;
            color: #000;
            font-family: "SegoeUI-Bold", Arial, "Helvetica CY", "Nimbus Sans L", sans-serif;
            font-size: 50px;
            font-weight: 400;
            line-height: 62px;
            display: inline-block;
            padding-bottom: 13px;
            min-width: 680px;
            box-sizing: border-box;
        }
        &__desc {
            text-align: center;
            font-size: 30px;
            color: #000;
            font-weight: 300;
            margin-top: 11px;
            line-height: 1;
            span {
                display: inline-block;
                background-color: #fff;
                border-radius: 3px;
                &.line {
                    &-1 {
                        line-height: 47px;
                        padding-bottom: 6px;
                        min-width: 680px;
                        box-sizing: border-box;
                    }
                    &-2 {
                        line-height: 1;
                        position: relative;
                        top: -9px;
                        padding-bottom: 16px;
                        padding-left: 15px;
                        padding-right: 15px;
                    }
                }
            }
            strong {
                color: #862dd9;
                font-family: "SegoeUI-Bold", Arial, "Helvetica CY", "Nimbus Sans L", sans-serif;
                font-weight: 400;
            }

        }
        &__lists {
            background-color: #fff;
            border-radius: 3px;
            margin-top: 45px;
        }
    }
    &-key-list {
        text-align: center;
        margin: 0;
        padding-top: 39px;
        padding-bottom: 22px;
        &__item {
            display: inline-block;
            vertical-align: top;
            width: 280px;
            &-1 {
                width: 280px;
            }
            &-2 {
                width: 280px;
                margin: 0 95px 0 105px;
            }
            &-3 {
                width: 310px;
                margin-right: 40px;
            }
        }
        &__icon {
            display: inline-block;
            &-1 {
                background: url(images/web-studios_1.svg) 0 0 no-repeat;
                width: 94px;
                height: 83px;
                margin-top: -1px;
            }
            &-2 {
                background: url(images/web-studios_2.svg) 0 0 no-repeat;
                width: 72px;
                height: 64px;
                margin-bottom: 18px;
            }
            &-3 {
                background: url(images/web-studios_3.svg) 0 0 no-repeat;
                width: 88px;
                height: 63px;
                margin-top: 3px;
                margin-bottom: 16px;
            }
        }
        &__title {
            padding-top: 10px;
            color: #000;
            font-family: "SegoeUI-Bold", Arial, "Helvetica CY", "Nimbus Sans L", sans-serif;
            font-size: 20px;
            font-weight: 400;
            line-height: 26px;
        }
        &__text {
            color: #000;
            font-size: 15px;
            line-height: 24px;
            margin-top: 19px;
        }
    }
    &-login {
        font-family: "SegoeUI-Regular", Arial, "Helvetica CY", "Nimbus Sans L", sans-serif;
        text-align: center;
        margin-top: -42px;
        margin-bottom: 38px;
        &__button {
            font-size: 22px;
            padding: 27px 0 31px 0;
            width: 337px;
        }
        &__link {
            display: inline-block;
            color: #a3a3a3;
            font-size: 16px;
            font-weight: 400;
            text-decoration: none;
            border-bottom: 1px solid;
            margin-top: 9px;
            line-height: 1;
            &:hover {
                border-bottom-color: transparent;
            }
        }
    }
    &-profit {
        font-family: "SegoeUI-Regular", Arial, "Helvetica CY", "Nimbus Sans L", sans-serif;
        color: #000;
        &__inner {
            background-color: #f5f4f4;
            border-radius: 3px;
            padding-top: 23px;
            padding-left: 40px;
            padding-bottom: 53px;
        }
        &__header {
            font-size: 30px;
            font-weight: 600;
            line-height: 42px;
        }
        &__item {
            display: inline-block;
            vertical-align: top;
            padding-top: 21px;
            position: relative;
            &-1 {
                margin-left: 55px;
                width: 255px;
            }
            &-2 {
                margin-left: 105px;
                width: 280px;
            }
            &-3 {
                margin-left: 85px;
                width: 305px;
            }
        }
        &__icon {
            &-1 {
                background: url(images/web-studios_schet.svg) 0 0 no-repeat;
                width: 51px;
                height: 29px;
                margin-top: 2px;
                margin-bottom: 6px;
            }
            &-2 {
                background: url(images/web-studios_tariffs.svg) 0 0 no-repeat;
                width: 51px;
                height: 37px;
            }
            &-3 {
                background: url(images/web-studios_user.svg) 0 0 no-repeat;
                width: 41px;
                height: 41px;
                margin-top: -5px;
                margin-bottom: 1px;
            }
        }
        &__number {
            color: #fff;
            font-family: "ProximaNova-Regular";
            font-size: 99px;
            font-weight: 400;
            line-height: 107px;
            position: absolute;
            top: 2px;
            left: -60px;
            &-1 {
                left: -52px;
            }
        }
        &__title {
            font-size: 20px;
            font-weight: 700;
            line-height: 20px;
            padding-top: 8px;
        }
        &__text {
            font-size: 15px;
            font-weight: 400;
            line-height: 24px;
            margin-top: 15px;
        }
    }
    &-content {
        font-family: "SegoeUI-Regular", Arial, "Helvetica CY", "Nimbus Sans L", sans-serif;
        padding-top: 37px;
        padding-bottom: 78px;
        &__title {
            // Text style for "Правила и опис"
            color: #212121;
            font-size: 24px;
            font-weight: 400;
            line-height: 26px;
            padding-bottom: 31px;
        }
        &__text {
            color: #262626;
            font-size: 15px;
            font-weight: 400;

            line-height: 26px;
            p {
                margin-top: 0;
                margin-bottom: 26px;
                line-height: 26px;
            }
            ul,
            ol {
                margin-top: 37px;
                padding-left: 15px;
                line-height: 30px;
                li {
                    padding-bottom: 5px;
                }
            }
        }
        &__link {
            text-decoration: none;
            position: relative;
            margin-top: 9px;
            display: inline-block;
            vertical-align: top;
            padding-left: 53px;
            &:before {
                position: absolute;
                top: 2px;
                left: 0;
                background: url(images/web-studios_doc.svg) 0 0 no-repeat;
                width: 41px;
                height: 58px;
                content: '';
            }
            span {
                font-family: "SegoeUI-Regular", Arial, "Helvetica CY", "Nimbus Sans L", sans-serif;
                color: #6922d3;
                font-size: 15px;
                font-weight: 400;
                // border-bottom: 1px solid;
                display: inline;
                text-decoration: underline;
                &:hover {
                    text-decoration: none;
                    border-bottom-color: transparent;
                }
            }
            i {
                color: #b7b6b6;
                font-size: 15px;
                font-style: italic;
            }
        }
    }
}

#OrderLiveSite{
    font-family: "SegoeUI-Regular", Arial, "Helvetica CY", "Nimbus Sans L", sans-serif;
	width:600px}
#OrderLiveSite a.modal__chose:hover:after{
	border-bottom:none;}
#OrderLiveSite .modal__select-label{
    font-family: "SegoeUI-Regular", Arial, "Helvetica CY", "Nimbus Sans L", sans-serif;
    font-weight: 400;
	display:inline-block;
	vertical-align:top;
	width:185px;
	font-size:15px;}
#OrderLiveSite .modal__checkbox-label{	font-size:15px;}
#OrderLiveSite input{
	display:inline-block;
	width:315px;
	height:35px;}
#OrderLiveSite input.error-input{
	border: 1px solid #e4005b;}
#OrderLiveSite .error-message{
	margin-left:188px;
	color:#e2005a;
	font-size:12px;}
#OrderLiveSite .btn{margin:15px 0 0 }

.container-inner {
    position: relative;
    padding-left: 140px;
    padding-right: 140px;
    box-sizing: border-box;
}
.text-center {
    text-align: center;
}
.ls-textarea {
    box-sizing: border-box;
    padding: 0px 12px;
    width: 315px;
    height: 111px;
    font-size: 15px;
    line-height: 33px;
    color: #000;
    border: 1px solid #dadada;
    outline: none;
    border-radius: 2px;
    resize: none;
}
.ls-textarea:focus {
  border: 1px solid #00b9ee;
  box-shadow: 0px 1px 6px -2px;	}
.ls-textarea:hover {
  border: 1px solid #00b9ee; }
.ls-textarea.error {
  border: 1px solid #e10059; }
  .ls-textarea.error + span.error {
    position: absolute;
    left: 0;
    bottom: 5px;
    font-size: 12px;
    line-height: 16px;
    color: #e10059; }

.ls-label-caption {
    display: block;
    margin-top: -18px;
    color: #cacaca;
}
.ls-modal {
    position: relative;
    &-hr {
        border: none;
        height: 1px;
        background-color: #000;
        opacity: .1;
        margin-left: -45px;
        margin-right: -45px;
        margin-top: 15px;
        margin-bottom: 25px;
    }
    &-title {
        font-family: "SegoeUI-Regular", Arial, "Helvetica CY", "Nimbus Sans L", sans-serif;
        font-weight: 500;
        font-size: 24px;
        line-height: 30px;
    }
}
.ls-modal-close {
    position: absolute;
    top: 29px;
    right: 41px;
    background: url(images/close.png) 0 0 no-repeat;
    width: 21px;
    height: 21px;
    cursor: pointer;
    opacity: .7;
    &:hover {
        opacity: 1;
    }
}
.ls-modal-button {
    margin-left: 189px !important;
    font-size: 18px;
    width: 257px;
}
.ls {
    &-title {
        position: relative;
        display: inline-block;
        background-color: #fff;
        border-radius: 3px 2px 2px 3px;
        color: #000;
        font-family: "SegoeUI-Bold", Arial, "Helvetica CY", "Nimbus Sans L", sans-serif;
        font-size: 24px;
        text-transform: uppercase;
        line-height: 53px;
        padding: 0 22px 0 20px;
        letter-spacing: -.5px;
        > span {
            position: absolute;
            left: 100%;
            bottom: 100%;
            margin-bottom: -12px;
            margin-left: -12px;
            color: #fff;
            font-family: "Roboto",Helvetica,Arial,sans-serif;
            font-size: 16px;
            font-weight: 700;
            line-height: 30px;
            background-color: #de0057;
            border-radius: 1px;
            padding: 0 12px 0 11px;
            letter-spacing: 0px;
        }
    }
    &-subtitle {
        color: #000;
        font-family: "Roboto",Helvetica,Arial,sans-serif;
        font-size: 48px;
        font-weight: 700;
        line-height: 42px;
        margin-top: 45px;
        margin-bottom: 10px;
        &_news {
            margin-top: 0;
            color: #fff;
        }
    }
    &-subtitle-caption {
        color: #fff;
        font-family: "SegoeUI-Regular", Arial, "Helvetica CY", "Nimbus Sans L", sans-serif;
        font-weight: 600;
        font-size: 20px;
        line-height: 20px;
        position: relative;
        z-index: 1;
        display: inline-block;
        padding: 9px 21px 13px 21px;
        margin-left: -18px;
        margin-top: 17px;
        margin-bottom: 25px;
        &:before {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #5ed197;
            content: '';
            transform: skewX(-12deg) translateZ(0);
            z-index: -1;
        }
    }
    &-text {
        color: #1c1c1c;
        font-family: "SegoeUI-Regular", Arial, "Helvetica CY", "Nimbus Sans L", sans-serif;
        font-size: 20px;
        line-height: 26px;
        margin-bottom: 10px;
        &-2 {
            margin-top: 24px;
        }
        &_small {
            font-size: 16px;
            line-height: 24px;
            margin-right: -20px;
        }
        &_profit {
            padding-top: 37px;
            font-size: 18px;
            line-height: 30px;
            padding-bottom: 36px;
        }
        &_certificate {
            padding-top: 12px;
            font-size: 18px;
            line-height: 30px;
            padding-bottom: 22px;
        }
        strong {
            font-family: "SegoeUI-Bold", Arial, "Helvetica CY", "Nimbus Sans L", sans-serif;
            font-weight: 400;
        }
    }
    &-list {
        margin-top: 22px;
        padding-left: 0;
        list-style: none;
        margin-bottom: 54px;
        li {
            font-family: "SegoeUI-Regular", Arial, "Helvetica CY", "Nimbus Sans L", sans-serif;
            color: #000;
            font-size: 24px;
            font-weight: 400;
            margin-bottom: 22px;
            span {
                position: relative;
                top: -2px;
                margin-right: 8px;
                display: inline-block;
                vertical-align: middle;
                background: url(images/lp_problem.svg) 0 0 no-repeat;
                width: 35px;
                height: 35px;
                color: #fff;
                font-size: 20px;
                line-height: 33px;
                text-align: center;
                font-family: "SegoeUI-Bold", Arial, "Helvetica CY", "Nimbus Sans L", sans-serif;
                font-weight: 400;
            }
        }
    }
    &-table {
        width: 100%;
        &__container {
            background-color: rgba(240, 240, 240, 0.3);
            border: 3px solid #f2f2f2;
            border-radius: 2px;
            margin-top: 56px;
            padding-bottom: 75px;
        }
        tr {
            td {
                vertical-align: top;
                width: 50%;
            }
        }
        &__cell {
            position: relative;
            padding-top: 46px;
            padding-left: 63px;
            padding-right: 15px;
            margin-bottom: -2px;
            &-2 {
                padding-left: 82px;
                padding-right: 30px;
                .ls-table__number {
                    left: 57px;
                }
            }
        }
        &__number {
            position: absolute;
            top: 31px;
            left: 28px;
            opacity: .2;
            color: #5ed197;
            // font-family: PTSerif, "Open Sans", Arial, "Helvetica CY", "Nimbus Sans L", sans-serif;
            font-family: "SegoeUI-Regular", Arial, "Helvetica CY", "Nimbus Sans L", sans-serif;
            font-size: 148px;
            line-height: 1;
        }
        &__title {
            color: #000;
            font-family: "Roboto",Helvetica,Arial,sans-serif;
            font-size: 24px;
            font-weight: 700;
            line-height: 26px;
            position: relative;
        }
        &__text {
            color: #262626;
            font-family: "SegoeUI-Regular", Arial, "Helvetica CY", "Nimbus Sans L", sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 26px;
            position: relative;
            margin-left: 33px;
            margin-top: 21px;
            &-4 {
                margin-top: 16px;
            }
        }
    }
    &-key-visual {
        background: url(images/key-visual.jpg) 0 0 no-repeat;
        background-size: cover;
        min-height: 765px;
        padding-top: 68px;
        box-sizing: border-box;
        padding-bottom: 50px;
        color: #fff;
        &__title {
            font-family: "Roboto",Helvetica,Arial,sans-serif;
            font-size: 65px;
            font-weight: 900;
            line-height: 66px;
            margin-top: 54px;
            margin-left: 70px;
            position: relative;
            &:before {
                position: absolute;
                bottom: -82px;
                left: -62px;
                background: url(images/quote.png) 0 0 no-repeat;
                width: 69px;
                height: 53px;
                background-size: contain;
                content: '';
            }
            &:after {
                position: absolute;
                top: -47px;
                right: 14px;
                background: url(images/unquote.png) 0 0 no-repeat;
                width: 69px;
                height: 53px;
                background-size: contain;
                content: '';
            }
        }
        &__text {
            font-family: "SegoeUI-Regular", Arial, "Helvetica CY", "Nimbus Sans L", sans-serif;
            font-size: 18px;
            line-height: 28px;
            margin-top: 57px;
            margin-left: 140px;
            width: 805px;
            letter-spacing: .28px;
            p {
                margin-top: 0;
                margin-bottom: 28px;
                strong {
                    font-weight: normal;
                    color: #fff;
                    display: inline-block;
                    background-color: #7828d5;
                    padding: 0 5px 0 7px;
                    line-height: 24px;
                    padding-bottom: 4px;
                    margin-bottom: -2px;
                    margin-left: -2px;
                    &.first {
                        padding-left: 10px;
                        padding-right: 10px;
                        margin-left: -10px;
                    }
                }
            }
        }
    }
    &-section {
        padding-bottom: 52px;
        &_news {
            padding-top: 32px;
            padding-bottom: 0;
            background: #43c483 url(images/news-bg.jpg) 50% 50% no-repeat;
            background-size: cover;
            margin-bottom: 130px;
        }
        &_profit {
            padding-bottom: 7px;
        }
        &_categories {
            padding-bottom: 0;
        }
    }
    &-price {
        margin-top: 7px;
        margin-left: -22px;
        display: inline-block;
        padding: 19px 24px 23px 24px;
        color: #fff;
        font-family: "Roboto",Helvetica,Arial,sans-serif;
        font-size: 44px;
        font-weight: 700;
        line-height: 22px;
        position: relative;
        z-index: 1;
        &:before {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #9100e4;
            content: '';
            transform: skewX(-12deg) translateZ(0);
            z-index: -1;
        }
        b {
            display: inline-block;
            margin-left: 9px;
        }
        span {
            display: inline-block;
            margin-left: 10px;
            vertical-align: top;
            font-size: 24px;
            font-weight: 400;
            line-height: 14px;
            i {
                font-style: normal;
                position: relative;
                font-size: 26px;
                font-weight: 400;
                &:before {
                    position: absolute;
                    top: 50%;
                    left: 1px;
                    margin-top: 3px;
                    width: 12px;
                    height: 1px;
                    background-color: #fff;
                    content: '';
                }
            }
        }
    }
    &-news {
        &__foot {
            text-align: center;
            height: 30px;
            padding-top: 13px;
        }
        &__button {
            font-size: 22px;
            padding: 15px 23px 23px 30px;
            width: 337px;
            margin-left: -15px;
            letter-spacing: 0.3px;
            height: auto;
        }
        &__caption {
            color: #4d4d4d;
            font-family: "SegoeUI-Regular", Arial, "Helvetica CY", "Nimbus Sans L", sans-serif;
            font-size: 20px;
            font-weight: 400;
            line-height: 24px;
            margin-top: 7px;
            margin-left: -23px;
        }
        &__pic {
            position: absolute;
            left: 566px;
            bottom: 0;
            background: url(images/news-pic.png) 0 0 no-repeat;
            width: 594px;
            height: 551px;
            background-size: contain;
        }
        &-list {
            list-style: none;
            padding-left: 0;
            margin-top: 40px;
            margin-left: 3px;
            margin-bottom: 0;
            width: 670px;
            &__item {
                position: relative;
                color: #fff;
                font-family: "Roboto",Helvetica,Arial,sans-serif;
                font-size: 24px;
                font-weight: 500;
                line-height: 28px;
                margin-bottom: 32px;
                span {
                    font-size: 18px;
                }
                &:before {
                    position: absolute;
                    content: '';
                }
                &-1 {
                    &:before {
                        background: url(images/lp_price1.svg) 0 0 no-repeat;
                        top: -2px;
                        left: -116px;
                        width: 90px;
                        height: 65px;
                    }
                }
                &-2 {
                    &:before {
                        background: url(images/lp_price2.svg) 0 0 no-repeat;
                        top: -2px;
                        left: -116px;
                        width: 92px;
                        height: 75px;
                    }
                }
                &-3 {
                    &:before {
                        background: url(images/lp_price3.svg) 0 0 no-repeat;
                        top: -2px;
                        left: -120px;
                        width: 96px;
                        height: 79px;
                    }
                }
                &-4 {
                    &:before {
                        background: url(images/lp_price4.svg) 0 0 no-repeat;
                        top: -2px;
                        left: -118px;
                        width: 94px;
                        height: 69px;
                    }
                }
            }
        }
    }
    &-table-profit {
        &__container {
            margin-top: 45px;
            padding-top: 37px;
            padding-bottom: 28px;
            background-color: #f3f3f3;
        }
        tr {
            td {
                vertical-align: top;
            }
        }
        &__cell {
            position: relative;
            &-1 {
                padding-left: 0;
                width: 258px;
            }
            &-2 {
                padding-left: 40px;
                width: 248px;
            }
            &-3 {
                padding-left: 50px;
                width: 268px;
            }
            &-4 {
                padding-left: 35px;
                width: 248px;
            }
        }
        &__number {
            position: absolute;
            top: -35px;
            left: -29px;
            color: #fff;
            font-family: "SegoeUI-Regular", Arial, "Helvetica CY", "Nimbus Sans L", sans-serif;
            font-size: 99px;
            line-height: 107px;
            z-index: -1;
            &-1 {
                left: -23px;
            }
        }
        &__icon {
            span {
                display: inline-block;
                vertical-align: top;
            }
            min-height: 84px;
            &-1 {
                span {
                    background: url(images/lp_users.svg) 0 0 no-repeat;
                    width: 87px;
                    height: 65px;
                }
            }
            &-2 {
                span {
                    vertical-align: top;
                    background: url(images/lp_ads.svg) 0 0 no-repeat;
                    width: 75px;
                    height: 57px;
                    margin-top: 4px;
                }
            }
            &-3 {
                span {
                    vertical-align: top;
                    background: url(images/lp_search.svg) 0 0 no-repeat;
                    width: 59px;
                    height: 60px;
                    margin-top: 4px;
                }
            }
            &-4 {
                span {
                    vertical-align: top;
                    background: url(images/lp_rost.svg) 0 0 no-repeat;
                    width: 74px;
                    height: 54px;
                    margin-top: 14px;
                }
            }
        }
        &__title {
            color: #000;
            font-family: "SegoeUI-Regular", Arial, "Helvetica CY", "Nimbus Sans L", sans-serif;
            font-size: 22px;
            font-weight: 600;
            line-height: 26px;
        }
        &__text {
            position: relative;
            font-family: "SegoeUI-Regular", Arial, "Helvetica CY", "Nimbus Sans L", sans-serif;
            font-size: 15px;
            line-height: 22px;
            color: #4d4d4d;
            margin-top: 20px;
            margin-left: 26px;
            z-index: 1;
            strong {
                font-family: "SegoeUI-Bold", Arial, "Helvetica CY", "Nimbus Sans L", sans-serif;
                color: #000;
                font-weight: 400;
            }
        }
    }
    &-section-categories {
        padding-top: 19px;
        margin-right: -15px;
        ul {
            list-style: none;
            display: inline-block;
            vertical-align: top;
            padding-left: 0;
            width: 280px;
            &.list {
                &-1 {
                    li {
                    }
                }
                &-2 {
                    padding-left: 62px;
                    width: 300px;
                }
                &-3 {
                    width: 210px;
                    padding-left: 34px;
                }
            }
            li {
                padding-left: 65px;
                color: #353535;
                font-family: "SegoeUI-Regular", Arial, "Helvetica CY", "Nimbus Sans L", sans-serif;
                font-size: 18px;
                line-height: 30px;
                font-weight: 400;
                padding-top: 15px;
                padding-bottom: 15px;
                position: relative;
                &:before {
                    position: absolute;
                    top: 50%;
                    margin-top: -15px;
                    left: 0;
                    background-repeat: no-repeat;
                    background-position: 0 0;
                    content: '';
                    width: 49px;
                    height: 38px;
                }
                &.icon {
                    &-1 {
                        &:before {
                            background-image: url(images/lp_finance.svg);
                        }
                    }
                    &-2 {
                        &:before {
                            background-image: url(images/lp_business.svg);
                            height: 40px;
                            margin-top: -19px;
                        }
                    }
                    &-3 {
                        &:before {
                            background-image: url(images/lp_building.svg);
                            height: 36px;
                            left: 2px;
                        }
                    }
                    &-4 {
                        &:before {
                            background-image: url(images/lp_house.svg);
                            height: 39px;
                            left: 2px;
                            margin-top: -18px;
                        }
                    }
                    &-5 {
                        &:before {
                            background-image: url(images/lp_medicine.svg);
                            margin-top: -17px;
                            height: 42px;
                            left: 2px;
                        }
                    }
                    &-6 {
                        &:before {
                            background-image: url(images/lp_family.svg);
                            height: 42px;
                            width: 50px;
                            margin-top: -21px;
                            left: 1px;
                        }
                    }
                    &-7 {
                        &:before {
                            background-image: url(images/lp_turism.svg);
                            left: 4px;
                        }
                    }
                    &-8 {
                        &:before {
                            background-image: url(images/lp_cars.svg);
                            width: 59px;
                            left: -7px;
                            height: 39px;
                            margin-top: -16px;
                        }
                    }
                    &-9 {
                        &:before {
                            background-image: url(images/lp_cloth.svg);
                            left: -3px;
                            margin-top: -19px;
                            height: 40px;
                        }
                    }
                    &-10 {
                        &:before {
                            background-image: url(images/lp_tv.svg);
                            height: 46px;
                            left: -3px;
                            margin-top: -23px;
                        }
                    }
                    &-11 {
                        &:before {
                            background-image: url(images/lp_parfume.svg);
                            height: 46px;
                            left: -2px;
                            margin-top: -21px;
                        }
                    }
                    &-12 {
                        &:before {
                            background-image: url(images/lp_furniture.svg);
                            height: 46px;
                            left: -3px;
                            margin-top: -22px;
                        }
                    }
                    &-13 {
                        &:before {
                            background-image: url(images/lp_instruments.svg);
                            height: 46px;
                            left: -2px;
                            margin-top: -23px;
                        }
                    }
                    &-14 {
                        &:before {
                            background-image: url(images/lp_products.svg);
                            height: 46px;
                            left: -2px;
                            margin-top: -21px;
                        }
                    }
                    &-15 {
                        &:before {
                            background-image: url(images/lp_work.svg);
                            height: 46px;
                            left: -3px;
                            margin-top: -19px;
                        }
                    }
                    &-16 {
                        &:before {
                            background-image: url(images/lp_teach.svg);
                            height: 46px;
                            left: -4px;
                            margin-top: -21px;
                        }
                    }
                    &-17 {
                        &:before {
                            background-image: url(images/lp_web.svg);
                            height: 46px;
                            left: -4px;
                            margin-top: -21px;
                        }
                    }
                    &-18 {
                        &:before {
                            background-image: url(images/lp_comp.svg);
                            height: 46px;
                            left: -4px;
                            margin-top: -17px;
                        }
                    }
                    &-19 {
                        &:before {
                            background-image: url(images/lp_ads2.svg);
                            height: 46px;
                            left: -3px;
                            margin-top: -20px;
                        }
                    }
                    &-20 {
                        &:before {
                            background-image: url(images/lp_cooking.svg);
                            height: 46px;
                            left: -3px;
                            margin-top: -20px;
                        }
                    }
                    &-21 {
                        &:before {
                            background-image: url(images/lp_video.svg);
                            height: 46px;
                            left: -3px;
                            margin-top: -22px;
                        }
                    }
                    &-22 {
                        &:before {
                            background-image: url(images/lp_music.svg);
                            height: 46px;
                            left: 1px;
                            margin-top: -16px;
                        }
                    }
                    &-23 {
                        &:before {
                            background-image: url(images/lp_games.svg);
                            height: 46px;
                            left: -1px;
                            margin-top: -19px;
                        }
                    }
                }
            }
        }
    }
    &-certificate {
        &__body {
            padding-left: 41px;
            margin-right: -80px;
        }
        &__foot {
            margin-top: -14px;
            background: url(images/stand.png) 0 0 no-repeat;
            width: 1026px;
            height: 59px;
            background-size: cover;
        }
        &__item {
            background: url(images/border.png) 0 0 no-repeat;
            width: 216px;
            height: 282px;
            background-size: contain;
            display: inline-block;
            margin-left: 12px;
        }
        &__pic {
            overflow: hidden;
            margin-top: 32px;
            margin-left: 32px;
            width: 152px;
            height: 220px;
            display: block;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
    &-order {
        background: url(images/border-gradient.png) 0 0 no-repeat;
        width: 1013px;
        height: 438px;
        margin-left: 150px;
        margin-top: -1px;
        margin-bottom: -34px;
        &__inner {
            padding-top: 44px;
            padding-left: 45px;
        }
        &__title {
            color: #000;
            font-family: "Roboto",Helvetica,Arial,sans-serif;
            font-size: 48px;
            font-weight: 700;
            line-height: 42px;
        }
        &__text {
            font-family: "SegoeUI-Regular", Arial, "Helvetica CY", "Nimbus Sans L", sans-serif;
            color: #919191;
            font-size: 22px;
            font-weight: 400;
            line-height: 42px;
            margin-top: 1px;
            margin-left: 2px;
        }
        &__control {
            margin-top: 20px;
            margin-left: 2px;
            margin-bottom: 44px;
        }
        &__button {
            font-size: 22px;
            width: 337px;
            padding-top: 11px;
            padding-bottom: 19px;
        }
        &__link {
            font-family: "SegoeUI-Regular", Arial, "Helvetica CY", "Nimbus Sans L", sans-serif;
            color: #595959;
            font-size: 16px;
            line-height: 18px;
            margin-left: 93px;
            margin-top: 17px;
            display: inline-block;
            vertical-align: top;
            position: relative;
            text-decoration: none;
            border-bottom: 1px solid;
            &:hover {
                border-bottom: 1px solid transparent;
            }
            &:before {
                position: absolute;
                top: -9px;
                left: -45px;
                background: url(images/lp_pdf.svg) 0 0 no-repeat;
                width: 28px;
                height: 36px;
                content: '';
            }
        }
        &__phone {
            display: inline-block;
            margin-top: 22px;
            font-family: "Roboto",Helvetica,Arial,sans-serif;
            text-decoration: none;
            color: #050505;
            font-size: 30px;
            font-weight: 500;
            line-height: 28px;
        }
    }
}
